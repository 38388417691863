var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-3"},[_vm._m(0),_c('ValidationObserver',{ref:"observer",staticClass:"px-3 px-md-4 f-14 pt-3",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"tag":"div","name":"contraseña actual","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"text-primary mt-2 mb-2"},[_c('strong',[_vm._v("Contraseña Actual")]),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('el-input',{attrs:{"type":"password","placeholder":"Ingrese contraseña actual"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('span',{staticClass:"text-danger d-block mt-1 f-13"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","vid":"confirmation","name":"nueva contraseña","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"text-primary mt-2 mb-2"},[_c('strong',[_vm._v("Contraseña Nueva")]),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('el-input',{attrs:{"type":"password","placeholder":"Ingrese una nueva contraseña"},model:{value:(_vm.form.nuevaPassword),callback:function ($$v) {_vm.$set(_vm.form, "nuevaPassword", $$v)},expression:"form.nuevaPassword"}}),_c('span',{staticClass:"text-danger d-block mt-1 f-13"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","name":"contraseña repetida","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"text-primary mt-2 mb-2"},[_c('strong',[_vm._v("Repetir Contraseña")]),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('el-input',{attrs:{"type":"password","placeholder":"Repita nuevamente la contraseña nueva"},model:{value:(_vm.form.confirmed),callback:function ($$v) {_vm.$set(_vm.form, "confirmed", $$v)},expression:"form.confirmed"}}),_c('span',{staticClass:"text-danger d-block mt-1 f-13"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('el-button',{staticClass:"mt-3",attrs:{"disabled":invalid,"type":"primary"},on:{"click":_vm.store}},[_vm._v("Guardar")])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-bottom py-3"},[_c('h1',{staticClass:"ml-4 my-0 text-title font-weight-normal"},[_vm._v(" Cambiar Contraseña ")])])}]

export { render, staticRenderFns }