


















































































import { Component, Vue } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
@Component
export default class CompanyData extends Vue {
    form = {
        password: '',
        nuevaPassword: ''
    };

    $refs!: {
        observer: any;
    };

    async store() {
        try {
            await agentModule.changePassword(this.form);
            this.form = {
                password: '',
                nuevaPassword: ''
            };
            this.$refs.observer.reset();
            this.$notify.success({
                title: 'Actualizado con éxito',
                message: 'La operación fue exitosa'
            });
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }
}
